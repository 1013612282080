import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const dataConfig = {
  headers: { 'Content-Type': 'application/json' }
};

export const getCMSummary = createAsyncThunk('/GetCMSummary', async (data) => {
  const response = await axios.post('/GetCMSummary', data, dataConfig);
  return response.data;
});

export const getCMSearch = createAsyncThunk('/GetCMSearch', async (data) => {
  const response = await axios.post('/GetCMSearch', data, dataConfig);
  return response.data;
});

export const getDetail = createAsyncThunk('/GetDetail', async (data) => {
  const response = await axios.post('/GetDetail', data, dataConfig);
  return response.data;
});

export const getPool = createAsyncThunk('/GetPool', async (data) => {
  const response = await axios.post('/GetPool', data, dataConfig);
  return response.data;
});

export const getMuadilList = createAsyncThunk('/GetMuadilList', async (data) => {
  const response = await axios.post('/GetMuadilList', data, dataConfig);
  return response.data;
});

export const getProductProp = createAsyncThunk('/GetProductProp', async (data) => {
  const response = await axios.post('/GetProductProp', data, dataConfig);
  return response.data;
});

export const getFileType = createAsyncThunk('/GetFileType', async (data) => {
  const response = await axios.get('/GetFileType', data, dataConfig);
  return response.data;
});

export const getWarranty = createAsyncThunk('/GetWarrantyList', async (params) => {
  const response = await axios.get(`/GetWarrantyList/${params[0]}/${params[1]}/${params[2]}/${params[3]}/${params[4]}`);
  return response.data;
});

export const getSelectedServiceRefund = createAsyncThunk('/GetSelectedServiceRefund', async (data) => {
  const response = await axios.post('/GetSelectedServiceRefund', data, dataConfig);
  return response.data;
});

export const exchangeOfferPrice = createAsyncThunk('/ExchangeOfferPrice', async (data) => {
  const response = await axios.post('/ExchangeOfferPrice', data, dataConfig);
  return response.data;
});

export const waitingPoolReceipt = createAsyncThunk('/WaitingPoolReceipt', async (data) => {
  const response = await axios.post('/WaitingPoolReceipt', data, dataConfig);
  return response.data;
});

export const waitingPoolReceiptUpdate = createAsyncThunk('/WaitingPoolReceiptUpdate', async (data) => {
  const response = await axios.post('/WaitingPoolReceiptUpdate', data, dataConfig);
  return response.data;
});

export const insertFisListControl = createAsyncThunk('/InsertFisListControl', async (data) => {
  const response = await axios.post('/InsertFisListControl', data, dataConfig);
  return response.data;
});

export const getFisListControl = createAsyncThunk('/GetFisListControl', async (params) => {
  const response = await axios.get(`/GetFisListControl/${params}`);
  return response.data;
});

export const getRefundInformationForm = createAsyncThunk('/GetRefundInformationForm', async (params) => {
  const response = await axios.get(`/GetRefundInformationForm/${params[0]}/${params[1]}/${params[2]}`);
  return response.data;
});

export const getExchangeVoucherInformation = createAsyncThunk('/GetExchangeVoucherInformation', async (params) => {
  const response = await axios.get(`/GetExchangeVoucherInformation/${params}`);
  return response.data;
});

export const getExchangeVoucherInvoicePrice = createAsyncThunk('/GetExchangeVoucherInvoicePrice', async (params) => {
  const response = await axios.get(`/GetExchangeVoucherInvoicePrice/${params}`);
  return response.data;
});

export const getTotalDayOfExchangeVoucher = createAsyncThunk('/GetTotalDayOfExchangeVoucher', async (params) => {
  const response = await axios.get(`/GetTotalDayOfExchangeVoucher/${params}`);
  return response.data;
});

export const getBrandDescriptionOfExchangeVoucher = createAsyncThunk('/GetBrandDescriptionOfExchangeVoucher', async (params) => {
  const response = await axios.get(`/GetBrandDescriptionOfExchangeVoucher/${params}`);
  return response.data;
});

export const getBrandCodeOfProduct = createAsyncThunk('/GetBrandCodeOfProduct', async (params) => {
  const response = await axios.get(`/GetBrandCodeOfProduct/${params}`);
  return response.data;
});

export const getWebPointInvoiceDetailFromSap = createAsyncThunk('/getWebPointInvoiceDetailFromSap', async (data) => {
  const response = await axios.post('/getWebPointInvoiceDetailFromSap', data, dataConfig);
  return response.data;
});

const generalFollowSlice = createSlice({
  name: 'generalFollow',
  initialState: {
    loading: false,
    error: '',
    productChangeSummary: [],
    pools: [],
    fileTypes: [],
    invoiceDetail: {},
    updatedInfoChat: '',
    activedTab: '1',
    updatedOfferPrice: '',
    updatedRecomendedPrice: '',
    updatedProductInformation: [],
    updatedRowCount: 0,
    updatedTopModelDifferentPrice: 0,
    topModelPriceDiscountRate: 0,
    exData: {},
    paidChangeDiscountRate: 0,
    updatedDataGridSort: [{ field: 'SIRA', sort: 'asc' }],
    discountButtonMessage: '',
    updatedWebPointInvoicePrice: 0
  },
  reducers: {
    updateNewData(state, action) {
      state.invoiceDetail = action.payload;
    },
    resetDetails(state) {
      state.invoiceDetail = [];
      state.exDatas = [];
      state.updatedInfoChat = '';
      state.updatedOfferPrice = '';
      state.updatedRecomendedPrice = '';
      state.updatedTopModelDifferentPrice = 0;
      state.paidChangeDiscountRate = 0;
      state.topModelPriceDiscountRate = 0;
      state.discountButtonMessage = '';
      state.updatedWebPointInvoicePrice = 0;
    },
    resetProductInformation(state) {
      state.updatedProductInformation = [];
    },
    exData(state, action) {
      state.exDatas = action.payload;
    },
    updateActiveTab(state, action) {
      state.activedTab = action.payload
    },
    updateInfoChat(state, action) {
      state.updatedInfoChat = action.payload
    },
    updateOfferPrice(state, action) {
      state.updatedOfferPrice = action.payload;
    },
    updateRecomendedPrice(state, action) {
      state.updatedRecomendedPrice = action.payload;
    },
    updateTopModelDifferentPrice(state, action) {
      state.updatedTopModelDifferentPrice = action.payload;
    },
    updateProductInformation(state, action) {
      state.updatedProductInformation = action.payload;
    },
    updateRowCount(state, action) {
      state.updatedRowCount = action.payload;
    },
    updateDataGridSort(state, action) {
      state.updatedDataGridSort = action.payload;;
    },
    updateDiscountRate(state, action) {
      state.paidChangeDiscountRate = action.payload;;
    },
    updateTopModelPriceDiscountRate(state, action) {
      state.topModelPriceDiscountRate = action.payload;;
    },
    updateDiscountButtonMessage(state, action) {
      state.discountButtonMessage = action.payload;;
    },
    updateWebPointInvoicePrice(state, action) {
      state.updatedWebPointInvoicePrice = action.payload;
    }

  },
  extraReducers: {
    [getDetail.pending]: (state) => {
      state.loading = true;
    },
    [getDetail.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getDetail.fulfilled]: (state, action) => {
      state.invoiceDetail = action.payload;
      state.loading = false;
    },
    [getPool.pending]: (state) => {
      state.loading = true;
    },
    [getPool.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getPool.fulfilled]: (state, action) => {
      state.pools = action.payload;
      state.loading = false;
    },
    [getFileType.pending]: (state) => {
      state.loading = true;
    },
    [getFileType.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getFileType.fulfilled]: (state, action) => {
      state.fileTypes = action.payload;
      state.loading = false;
    },
    [getProductProp.pending]: (state) => {
      state.loading = true;
    },
    [getProductProp.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getProductProp.fulfilled]: (state, action) => {
      state.productData = action.payload;
      state.loading = false;
    },
    [exchangeOfferPrice.pending]: (state) => {
      state.loading = true;
    },
    [exchangeOfferPrice.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [exchangeOfferPrice.fulfilled]: (state, action) => {
      state.updatedOfferPrice = action.payload;
      state.loading = false;
    },
  }
});
export const generalFollowActions = generalFollowSlice.actions;
export default generalFollowSlice;
