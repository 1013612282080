import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, TextField, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import React from "react";
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { generalFollowActions } from 'src/store/slices/generalFollowSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  div: {
    fontWeight: 'bold',
    fontSize: '14px',
    width: '100%',
    color: 'red'
  }
}));

function Refund() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);

  const [expanded, setExpanded] = React.useState(true);
  const [bankCode, setBankCode] = React.useState(invoiceData[0]?.Exchange?.BankCode);
  const [bankName, setBankName] = React.useState(invoiceData[0]?.Exchange?.BankName);
  const [ibanNo, setIbanNo] = React.useState(invoiceData[0]?.Exchange?.IBANNO);
  const [webSalesInvoiceNumber, setWebSalesInvoiceNumber] = React.useState(invoiceData[0]?.Exchange?.WebSalesInvoiceNumber);
  const ibanNumber = useSelector((state) => state.generalFollowSlice.invoiceDetail)[0]?.Exchange?.IBANNO;
  const TRiban = ibanNumber?.substring(2);

  function changeBankCode(val) {//Banka kodu
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.BankCode = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setBankCode(val)
  }

  function changeBankName(val) {//Banka Adı
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.BankName = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setBankName(val)
  }

  function changeIbanNo(val) {//Banka Iban
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.IBANNO = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setIbanNo(val)
  }

  function changeWebSalesInvoiceNumber(val) {//Web Satış Fatura Numarası
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.WebSalesInvoiceNumber = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setWebSalesInvoiceNumber(val)
  }
  return (
    <>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.div} variant="subtitle1">
            Banka İşlemleri
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}  >
            <Grid item xs={12} >
              <Tooltip title="Banka Adı">
                <TextField
                  id="input-with-icon-textfield"
                  label="Banka Adı"
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={bankName}
                  onChange={(event) => { changeBankName(event.target.value); }}
                  autoComplete="off"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <InputMask
                mask="TR************************"
                value={ibanNo}
                disabled={false}
                maskChar={null}
                onChange={(event) => { changeIbanNo(event.target.value); }}>
                {() => <TextField id="input-with-icon-textfield"
                  label="Iban No"
                  size="small"
                  fullWidth
                  autoComplete="off"
                  variant="outlined" />}
              </InputMask>
            </Grid>
            <Grid item xs={12} >
              <Tooltip title="Web Satış Fatura Numarası">
                <TextField
                  id="input-with-icon-textfield"
                  label="Web Satış Fatura Numarası"
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={webSalesInvoiceNumber}
                  onChange={(event) => { changeWebSalesInvoiceNumber(event.target.value); }}
                  autoComplete="off"
                />
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>

  );
}

export default Refund;

