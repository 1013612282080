import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Card, CardContent } from '@mui/material';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';
import React from 'react';
import ComponentRoleAuto from 'src/components/ComponentRoleAuto';
import MenuRoleAutho from 'src/components/MenuRoleAutho';
import UserRoleAutho from 'src/components/UserRoleAutho';



const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI",
    width: '100%',
    height: '100%'
  }

}));



function Authorization() {
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Card>
        <CardContent>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList variant="fullWidth" onChange={handleChange} aria-label="lab API tabs example"
                sx={{
                  textColor: '#424242',
                  "& .css-xe9pe1-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                    color: "#FF9800"
                  }, "& .css-10hkket.Mui-selected": {
                    color: "#FF9800"
                  }
                }}
                TabIndicatorProps={{ style: { background: '#FF9800' } }}>
                <Tab sx={{ width: '100%' }} icon={<SupervisedUserCircleIcon />} label="Kullanıcı Rol Yetkilendirme" value="1" />
                <Tab sx={{ width: '100%' }} icon={<AutoAwesomeMotionIcon />} label="Menü Yetkilendirme" value="2" />
                <Tab sx={{ width: '100%' }} icon={<SwipeRightIcon />} label="İşlem Yetkilendirme" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <UserRoleAutho />
            </TabPanel>
            <TabPanel value="2">
              <MenuRoleAutho />
            </TabPanel>
            <TabPanel value="3">
              <ComponentRoleAuto />
            </TabPanel>
          </TabContext>
        </CardContent>
      </Card>
    </>
  );
}

export default Authorization;
