import {
  Box, Card, CardContent, Grid, TextField, Typography, Autocomplete, IconButton, Dialog, DialogActions,
  DialogContent, Table, TableContainer, TableHead, TableCell, TableRow, TableBody, Backdrop, CircularProgress, Button, Alert
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DifferentModelSuggest from 'src/components/DifferentModelSuggest';
import DiffModelSuggestSolCenter from 'src/components/DiffModelSuggestSolCenter';
import ExchangeOfficeSubStatus from 'src/components/ExchangeOfficeSubStatus';
import SolutionCenterSubStatus from 'src/components/SolutionCenterSubStatus';
import { getChangeReasons, getChangeReasonWithUp } from 'src/store/slices/commonSlice';
import { getMaterialCost } from 'src/store/slices/solutionCenterProcessSlice';
import { generalFollowActions } from 'src/store/slices/generalFollowSlice';
import SapRefundProcess from './SapRefundProcess';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { getUser, getUserRole } from 'src/utils/Common';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import MaterialSearch from 'src/components/Selects/MaterialSearch';
import GroupIcon from '@mui/icons-material/Group';
import axios from 'src/utils/axios';
import { Height } from '@mui/icons-material';
import { getReceiptInfoChat } from 'src/store/slices/informationChatSlice';

const useStyles = makeStyles((theme) => ({
  cardscontent: {
    minHeight: 400,
    width: '100%'
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  div: {
    fontWeight: 'bold',
    fontSize: '12px',
    width: '100%'
  },
  div2: {
    fontSize: '12px',
    width: '100%'
  },
  icerikBilgi: {
    margin: '0px',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    color: 'black',
    marginBottom: '7.5px'
  },
  autocomplete: {
    fontSize: '10px'
  },
  button: {
    color: '#FF9800',
    backgroundColor: '#424242',
    '&:hover': {
      color: '#FF9800',
      backgroundColor: '#424242'
    }
  }
}));

function ExchangeInformation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRole = getUserRole().Component
  const Role = getUserRole().RoleId
  const SessionUser = getUser();
  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);
  const changeReasonData = useSelector((state) => state.commonSlice.changeReasons);
  const upChangeData = useSelector((state) => state.commonSlice.upChangeReasons);
  const exData = useSelector((state) => state.generalFollowSlice.exDatas);

  const [actionReasonSavePopUp, setActionReasonSavePopUp] = useState(false);
  const [outOfStock, setOutOfStock] = React.useState(invoiceData[0]?.Exchange?.NoneProduct)
  const [materialDsc, setMaterialDsc] = React.useState(invoiceData[0]?.Exchange?.MaterialDsc)
  const [outOfStockPrice, setOutOfStockPrice] = React.useState(invoiceData[0]?.UnsuppliedStokPrice)
  const [changeReasonList, setChangeReasonList] = React.useState(changeReasonData.filter(i => i.ChangeReason == invoiceData[0]?.Exchange?.ExchangeResonMain)[0]);
  const [upChangeReason, setUpChangeReasons] = React.useState(null);
  const [materialCostPopUp, setMaterialCostPopUp] = React.useState(false);
  const [materialCostData, setMaterialCostData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [exhanchangeEditNote, setExhanchangeEditNote] = React.useState("");
  const receiptSHFNumbers = invoiceData[0].Apply.ReceiptSHFNumbers;
  const numbers = receiptSHFNumbers.split(',').map(num => num.trim());
  const showAlert = !numbers.includes("3194") && !numbers.includes("571"); // testteki hali 3161 ama canlı için 3194 

  // const [airConditionerReceiptNumber, setAirConditionerReceiptNumber] = React.useState(invoiceData[0]?.Exchange.AirconditionerReceiptNo );
  const [airConditionerReceiptNumber, setAirConditionerReceiptNumber] = React.useState(null);

  useEffect(() => {
    if (!invoiceData[0]?.Exchange?.ExchangeResonMain) {
      return;
    }
    else {
      dispatch(getChangeReasons()).then(a => {
        dispatch(getChangeReasonWithUp(invoiceData[0]?.Exchange?.ExchangeResonMain))
          .then(b => {
            var temp = b.payload.filter(i => i?.DEGISIM_NEDENI == invoiceData[0]?.Exchange?.ExchangeReson)[0]
            setUpChangeReasons(temp)
          });
      });
    }
  }, [dispatch]);


  function AirConReceiptNo(val) {
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.AirconditionerReceiptNo = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setAirConditionerReceiptNumber(val)
  }

  function changeReason(val) {//Değişim nedeni
    if (val?.ChangeReason) {
      dispatch(getChangeReasonWithUp(val?.ChangeReason))
        .then(b => {
          var tempData = JSON.parse(JSON.stringify(invoiceData))
          tempData[0].Exchange.ExchangeResonMain = val?.ChangeReason
          tempData[0].Exchange.ExchangeReson = b?.payload[0].DEGISIM_NEDENI
          tempData[0].Exchange.ExchangeReasonMainDsc = val?.Dsc
          tempData[0].Exchange.ExchangeReasonSurveyCheck = b?.payload[0].ANKET_KONTROL
          dispatch(generalFollowActions.updateNewData(tempData))
          setChangeReasonList(val)
          setUpChangeReasons(b?.payload[0])
        });
    }
  }


  function changeUpReason(val) {//Değişim nedeni detay
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.ExchangeReson = val?.DEGISIM_NEDENI
    tempData[0].Exchange.ExchangeReasonMainDsc = val?.ACIKLAMA
    tempData[0].Exchange.ExchangeReasonSurveyCheck = val?.ANKET_KONTROL
    dispatch(generalFollowActions.updateNewData(tempData))
    setUpChangeReasons(val)
  }

  const setChangeMaterial = param => {
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.NoneProduct = param?.MALZEME_STOK_NO
    dispatch(generalFollowActions.updateNewData(tempData))
    setOutOfStock(param.MALZEME_STOK_NO)
    setMaterialDsc(param.ACIKLAMA)
    setOutOfStockPrice(param.FIYAT)
    var total = parseFloat(param.FIYAT) + parseFloat(exData[0].Exchange.RecomendedPrice);
    dispatch(generalFollowActions.updateRecomendedPrice(total))
  };

  function changeSupplicedStok(val) {//Kdvli fiyat
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].UnsuppliedStokPrice = val
    dispatch(generalFollowActions.updateNewData(tempData))
    setOutOfStockPrice(val)
  }

  function GetMaterialCost(receiptNo) {
    setLoading(true)
    dispatch(getMaterialCost(receiptNo)).then(response => {
      if (response?.payload?.length > 0) {
        setMaterialCostPopUp(true)
        setMaterialCostData(response?.payload);
      }
      setLoading(false)
    })
  }


  function GetReceiptDetail() {
    dispatch(getReceiptInfoChat([invoiceData[0]?.Exchange?.ApplyNo, invoiceData[0]?.Exchange?.Service])).then(response => { }).catch(err => { return });
  }

  function handleExchangeReasonSave() {

    console.log(invoiceData[0])
    const data = {
      Status: invoiceData[0]?.Exchange?.Status,
      ExchangeReasonDetail: invoiceData[0]?.Exchange?.ExchangeReson,
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString(),
      ServiceNumber: invoiceData[0]?.Exchange?.Service.toString(),
      UserID: invoiceData[0]?.UserID,
      Note: `Değişim Nedeni: ${invoiceData[0]?.Exchange?.ExchangeReasonMainDsc} olarak değiştirilmiştir. Mesaj: ${exhanchangeEditNote}`,
    }
    if (exhanchangeEditNote.length < 9) {
      return;
    }
    setLoading(true);
    axios.post('ExchanceReasonEditSave', data, {
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      GetReceiptDetail()
      setActionReasonSavePopUp(false);
      setLoading(false);
      return
    }).catch(err => {
      setLoading(false);
      return;
    });
  }


  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {userRole?.filter(i => i.COMPONENT_NAME == "SapRefundProcess").length > 0 ? <SapRefundProcess /> : null}
      <Card className={classes.cardscontent} sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }} >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography sx={{ width: '100%', fontWeight: 'bold', color: 'red', fontSize: '14px' }} variant="subtitle1">
                Değişim Bilgileri
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
            </Grid>
            <Grid item xs={2} md={2}>
              <Tooltip title="Değişim Nedeni Değiştir">
                <IconButton className={classes.action} key="save" onClick={() => setActionReasonSavePopUp(true)}>
                  <SaveAsIcon sx={{ color: '#424242' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ paddingTop: 10 }}>
            <Grid item xs={12} md={12}>
              <Autocomplete
                id="combo-box-demo"
                options={changeReasonData}
                disabled={(![1, 2, 6].includes(Role[0]?.ROLE_ID)) || invoiceData[0].AronStatus === 5 ? true : false}
                getOptionLabel={(i) => i.Dsc}
                value={changeReasonList || null}
                renderInput={(params) => <TextField {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: '14px' },
                  }}
                  size='small' label="Değişim Nedeni" />}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body2" style={{ fontSize: '14px' }}>{option.Dsc}</Typography>
                  </li>
                )}
                onChange={(event, val) => {
                  changeReason(val)
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                id="combo-box-demo"
                options={upChangeData}
                getOptionLabel={(i) => i.ACIKLAMA}
                disabled={(![1, 2, 6].includes(Role[0]?.ROLE_ID)) || invoiceData[0].AronStatus === 5 ? true : false}
                value={upChangeReason || null}
                size="small"
                renderInput={(params) => <TextField {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: '14px' },
                  }}
                  label="Detay"
                  size='small'
                  sx={{ fontSize: '12px' }} />}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body2" style={{ fontSize: '14px' }}>{option.ACIKLAMA}</Typography>
                  </li>
                )}
                onChange={(event, val) => {
                  changeUpReason(val)
                }}
              />
            </Grid>
            {userRole?.filter(i => i.COMPONENT_NAME == "SolutionCenterSubStatus").length > 0 ? <SolutionCenterSubStatus /> : null}
            {userRole?.filter(i => i.COMPONENT_NAME == "ExchangeOfficeSubStatus").length > 0 ? <ExchangeOfficeSubStatus /> : null}
            <Grid item xs={12} md={12}>
              <TextField
                id="input-with-icon-textfield"
                label="Servis Notu"
                size="small"
                className={classes.div}
                inputProps={{
                  style: { fontSize: 12, fontWeight: 'bold' }
                }}
                value={invoiceData[0]?.Exchange?.ServiceNote ? invoiceData[0]?.Exchange?.ServiceNote : '-'}
                fullWidth
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <MaterialSearch
                setMalzeme={setChangeMaterial}
                setProduct={invoiceData[0]?.Model?.ProductId}
                value={
                  {
                    MALZEME_STOK_NO: outOfStock,
                    ACIKLAMA: materialDsc
                  } || ''
                }
                searchParam=""
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                id="input-with-icon-textfield"
                label="KDV'li Fiyat"
                size="small"
                fullWidth
                type="number"
                variant="outlined"
                value={outOfStockPrice}
                disabled
                onChange={(event) => { changeSupplicedStok(event.target.value); }}
              />
            </Grid>
            {/* <Grid item xs={2} md={2}></Grid> */}
            <Grid item xs={6} md={6} container justifyContent="flex-end">
              <Tooltip title="Teminsiz Stok Fiş Detayı" >
                <Button sx={{ width: '175px' }} size="medium" className={classes.button} onClick={() => { GetMaterialCost(invoiceData[0]?.Apply?.ReceiptNumber) }}>Detay
                  <CreditScoreIcon sx={{ ml: 1 }} />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          {Role?.filter(i => i.ROLE_ID == 3).length > 0 || Role?.filter(i => i.ROLE_ID == 4).length > 0 ?
            null : <Grid container spacing={2}> <Grid item xs={12} md={12}>
              <Typography variant="body2" color="textSecondary" component="div" className={classes.icerikBilgi}>
                Değişim Türü:&nbsp;&nbsp;&nbsp;&nbsp;
                <Typography variant="body2" color="textSecondary" component="span" className={classes.div2}>
                  {invoiceData[0]?.ChangeTypeAron ? invoiceData[0]?.ChangeTypeAron : '-'}
                </Typography>
              </Typography>
            </Grid></Grid>}
        </CardContent>
      </Card>
      <Grid item xs={12} style={{ paddingTop: 5 }}>
        {userRole?.filter(i => i.COMPONENT_NAME == "DiffModelSuggestSolCenter").length > 0 ? <DiffModelSuggestSolCenter /> : null}
        {userRole?.filter(i => i.COMPONENT_NAME == "DifferentModelSuggest").length > 0 ? <DifferentModelSuggest /> : null}
      </Grid>


      <Dialog
        open={materialCostPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Tip</TableCell>
                      <TableCell align="left">Kod</TableCell>
                      <TableCell align="center">Açıklama</TableCell>
                      <TableCell align="left">Miktar</TableCell>
                      <TableCell align="center">Fiyat</TableCell>
                      <TableCell align="center">Toplam Fiyat</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materialCostData?.map((row) => (
                      <TableRow
                        key={row.KOD}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="center">{row.TIP ? row.TIP : '-'}</TableCell>
                        <TableCell align="left">{row.KOD ? row.KOD : '-'}</TableCell>
                        <TableCell align="center">{row.ACIKLAMA ? row.ACIKLAMA : '-'}</TableCell>
                        <TableCell align="left">{row.MIKTAR ? row.MIKTAR : '-'}</TableCell>
                        <TableCell align="center">{row.FIYAT ? row.FIYAT : '-'}</TableCell>
                        <TableCell align="center">{row.TOPLAM_FIYAT ? row.TOPLAM_FIYAT : '-'}</TableCell>
                      </TableRow>
                    ))}
                    <TableCell align="center" sx={{ border: 'none' }}></TableCell>
                    <TableCell align="left" sx={{ border: 'none' }}></TableCell>
                    <TableCell align="center" sx={{ border: 'none' }}></TableCell>
                    <TableCell align="left" sx={{ border: 'none' }}></TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold', border: 'none' }}>{('Toplam')}</TableCell>
                    <TableCell align="center" sx={{ border: 'none' }}>{materialCostData[0]?.TOPLAM ? materialCostData[0]?.TOPLAM : '0'}</TableCell>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            sx={{ color: '#FF9800' }}
            onClick={() => {
              setMaterialCostPopUp(false)
            }}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={actionReasonSavePopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Autocomplete
                id="combo-box-demo"
                options={changeReasonData}
                disabled={(![1, 2, 6].includes(Role[0]?.ROLE_ID)) || invoiceData[0].AronStatus === 5 ? true : false}
                getOptionLabel={(i) => i.Dsc}
                value={changeReasonList || null}
                renderInput={(params) => <TextField {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: '14px' },
                  }}
                  size='small' label="Değişim Nedeni" />}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body2" style={{ fontSize: '14px' }}>{option.Dsc}</Typography>
                  </li>
                )}
                onChange={(event, val) => {
                  changeReason(val)
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                id="combo-box-demo"
                options={upChangeData}
                getOptionLabel={(i) => i.ACIKLAMA}
                disabled={(![1, 2, 6].includes(Role[0]?.ROLE_ID)) || invoiceData[0].AronStatus === 5 ? true : false}
                value={upChangeReason || null}
                size="small"
                renderInput={(params) => <TextField {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: '14px' },
                  }}
                  label="Detay"
                  size='small'
                  sx={{ fontSize: '12px' }} />}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body2" style={{ fontSize: '14px' }}>{option.ACIKLAMA}</Typography>
                  </li>
                )}
                onChange={(event, val) => {
                  changeUpReason(val)
                }}
              />

            </Grid>
            <Grid item xs={12} md={12}>
                {showAlert && (
                  <Grid item xs={12} md={12}>
                    <Alert severity="warning">
                      Ürün üzerinde tanımlı ve aktif hasar hizmet kodu aktif değil.
                    </Alert>
                  </Grid>
                )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ "& > :not(style)": { m: 1 } }} height={150} >
                <Tooltip title="Değişim Nedeni Açıklama Giriniz...">
                  <TextField
                    multiline
                    rows={5}
                    label="Değişim Nedeni Açıklama Giriniz...(En az 10 harf.)"
                    fullWidth
                    value={exhanchangeEditNote}
                    onChange={(e) => setExhanchangeEditNote(e.target.value)}
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => { handleExchangeReasonSave() }}
            component="span"
            sx={{ color: '#FF9800', backgroundColor: '#424242' }}
          >
            {'Kaydet'}
          </Button>
          <IconButton
            sx={{ color: '#FF9800' }}
            onClick={() => {
              setActionReasonSavePopUp(false)
            }}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ExchangeInformation;