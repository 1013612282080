import { Card, CardContent, Grid, TextField, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApprovalProcess from 'src/components/ApprovalProcess';
import DirectionProcess from 'src/components/DirectionProcess';
import WebPoint from 'src/components/WebPoint';
import WebPointCancel from 'src/components/WebPointCancel';
import InvoiceRefund from 'src/components/InvoiceRefund';
import { getUserRole } from 'src/utils/Common';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'src/utils/axios';


import { generalFollowActions } from 'src/store/slices/generalFollowSlice';

const useStyles = makeStyles((theme) => ({
  cardscontent: {
    minHeight: 400,
    width: '100%'
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  div: {
    fontWeight: 'bold',
    fontSize: '12px',
    width: '100%',
    textDecoration: 'underline'
  },
  div2: {
    fontSize: '12px',
    width: '100%',
    fontWeight: 'bold'
  },
  messageBox: {
    margin: '0px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    color: '#b02121b0'
  },
  icerikBilgi: {
    margin: '0px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    color: 'black',
    marginBottom: '7.5px'
  }
}));

function InformationChat() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRole = getUserRole().Component
  const Role = getUserRole().RoleId;

  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);
  const ChatData = useSelector((state) => state.informationChatSlice.informationChat);
  const UpdatedInfoChat = useSelector((state) => state.generalFollowSlice.updatedInfoChat);
  const [infoChatContent, setInfoChatContent] = React.useState('');
  var airConditionerModelCodes = [];
  const topProductIds = [18924, 27048, 28063];

  useEffect(() => {
    getAirConditionerModelCodes();
  }, [])

  function changeChatInfoContent(val) {
    dispatch(generalFollowActions.updateInfoChat(val))
    setInfoChatContent(val)
  }
  function DeleteVal() {
    dispatch(generalFollowActions.updateInfoChat(''))
    setInfoChatContent('')
  }

  const checkData = () => {
    if (invoiceData[0]?.Model?.ProductId && airConditionerModelCodes.includes(invoiceData[0].Model.ProductId)) {
      // ilgili veri bulundu.
      var tempData = JSON.parse(JSON.stringify(invoiceData));
      tempData[0].Exchange.IsAirConditionerProduct = 1;
      dispatch(generalFollowActions.updateNewData(tempData))
    } else {
      // İlgili veri bulunamadı veya yok.
      var tempData = JSON.parse(JSON.stringify(invoiceData));
      tempData[0].Exchange.IsAirConditionerProduct = 0;
      dispatch(generalFollowActions.updateNewData(tempData))
    }
  };

  const getAirConditionerModelCodes = () => {
    axios.get("/GetAirConditionerModels", {}).then(response => {
      const data = response.data;
      data.forEach((i) => {
        airConditionerModelCodes.push(i.MODEL_KODU);
      });
      checkData();
    });
  };

  return (
    <>
      <Card sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
        <CardContent className={classes.cardscontent}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="body2" color="textSecondary" component="div" className={classes.icerikBilgi}>
                Değişim Onay Türü:&nbsp;&nbsp;&nbsp;&nbsp;
                <Typography variant="body2" color="textSecondary" component="span" sx={{ color: '#b02121b0', fontSize: '12px', fontWeight: 'bold', width: '100%' }}>
                  {invoiceData[0]?.Consumer?.UpTypeDesc ? invoiceData[0]?.Consumer?.UpTypeDesc : '-'}- {invoiceData[0]?.Consumer?.TypeDesc ? invoiceData[0]?.Consumer?.TypeDesc : '-'}
                </Typography>
              </Typography>
            </Grid>
            {(Role?.filter(i => i.ROLE_ID !== 11).length > 0) ?
              <>
                <Grid item xs={12} md={12} >
                  <TextField
                    id="input-with-icon-textfield"
                    label="Mesaj"
                    size="small"
                    className={classes.div2}
                    value={UpdatedInfoChat}
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={3}
                    onChange={
                      (event) => { changeChatInfoContent(event.target.value) }
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton size="small" onClick={() => { DeleteVal() }}>
                            <CloseIcon sx={{ color: '#424242', fontSize: 15 }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={12} md={12} style={{ height: 300, width: '100%', overflowY: 'auto' }}>

                  {Object.keys(ChatData).length > 0 ?
                    ChatData?.map((row, index) => (
                      <Grid item xs={12} md={12} key={index}>
                        <Card>
                          <CardContent sx={{ margin: '10px' }}>
                            <Grid container spacing={2}>
                              <Grid item xs={5}> <Typography sx={{ padding: '0%', textAlign: 'left' }} className={classes.div} variant="body2"
                                color="textSecondary">
                                {row.ADSOYAD}
                              </Typography>
                              </Grid>
                              <Grid item xs={2}>
                              </Grid>
                              <Grid item xs={5}> <Typography sx={{ padding: '0%', textAlign: 'right' }} className={classes.messageBox} variant="body2">
                                {row.TANIM}
                              </Typography>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={3}><Typography sx={{ padding: '0%', textAlign: 'left' }} className={classes.div} variant="body2"
                                color="textSecondary">
                                {row.ADIMTARIH}
                              </Typography>
                              </Grid>
                              <Grid item xs={6}>
                              </Grid>
                              <Grid item xs={3}> <Typography sx={{ padding: '0%', textAlign: 'right' }} className={classes.messageBox} variant="body2">
                                {row.DURUMACIKLAMA}
                              </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography sx={{ padding: '1%', textAlign: 'center' }} className={classes.div2} variant="body2"
                                color="textSecondary">
                                {row.ACIKLAMA}
                              </Typography>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )) : <Grid></Grid>}
                </Grid>
              </>
              :
              null
            }
          </Grid>
        </CardContent>
      </Card>
      {(Role?.filter(i => i.ROLE_ID !== 11).length > 0) && (userRole?.filter(i => i.COMPONENT_NAME == "InvoiceRefund").length > 0 || invoiceData[0]?.Consumer?.ConsumerType == 2 || invoiceData[0]?.Consumer?.ConsumerType == 3) ? <InvoiceRefund /> : null}
      <Grid item xs={12}>
        {
          invoiceData[0]?.Consumer.UpTypeId == 7 &&
            [23, 14, 15, 31, 32, 33].includes(invoiceData[0]?.Exchange?.Status)
            && (
              (invoiceData[0]?.Apply.BrandCode === 3 || invoiceData[0]?.Apply.BrandCode === 4) && (invoiceData[0]?.Consumer?.ConsumerType === 1) && (!topProductIds.includes(invoiceData[0]?.Model?.TopProductId))
            )
            &&
            invoiceData[0]?.Exchange.IsAirConditionerProduct === 0
            &&
            (Role?.filter(i => i.ROLE_ID !== 11).length > 0)
            ? <WebPoint />
            : null
        }
      </Grid>
      {/* <Grid item xs={12} style={{ paddingTop: 5 }}>
        {(invoiceData[0]?.Consumer.UpTypeId == 7 && invoiceData[0]?.Exchange.GPSNO != 0 && invoiceData[0]?.Exchange?.Status === 31) ? <WebPointCancel /> : null}
      </Grid> */}
      <Grid item xs={12} style={{ paddingTop: 5 }} >
        {/* Sonradan düzeltilecektir. */}
        {(Role?.filter(i => i.ROLE_ID !== 11).length > 0) ?
          <ApprovalProcess /> : null
        }
      </Grid>
      <Grid item xs={12}>
        {(invoiceData[0]?.Consumer?.ConsumerType == 3) && (Role?.filter(i => i.ROLE_ID !== 11).length > 0) ? <DirectionProcess /> : (invoiceData[0]?.Consumer?.ConsumerType != 3 && invoiceData[0]?.Exchange?.ExchangeResonMain == 90 && Role?.filter(i => i.ROLE_ID !== 11).length > 0) ? <DirectionProcess /> : null}
      </Grid>
    </>
  );
}

export default InformationChat;