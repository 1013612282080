import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import PersonIcon from '@mui/icons-material/Person';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TourIcon from '@mui/icons-material/Tour';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import React from 'react';

export const getMenu = () => {

  return Menu;
}


const Menu = [
  {
    title: 'Yönetim',
    items: [
      {
        title: 'Kullanıcı',
        href: '/Kullanici',
        icon: <PersonIcon />,
      },
      // {
      //   title: 'Yönetim Paneli',
      //   href: '/YonetimPaneli',
      //   icon: <PieChartOutlineIcon />,
      // }
    ]
  },
  {
    title: 'Uygulamalar',
    items: [
      {
        title: 'Kuyruk Atama',
        icon: <DeviceHubIcon />,
        href: '/AtamaEkrani'
      },
      {
        title: 'Kontrol ve Onay',
        icon: <DoneAllIcon />,
        href: '/KontrolOnayEkrani'
      },
      {
        title: 'Yetkilendirme',
        icon: <PeopleAltIcon />,
        href: '/Yetkilendirme'
      },
      {
        title: 'Dinamik Ekranlar',
        icon: <DynamicFeedIcon />,
        href: '/DinamikEkranlar'
      },
      {
        title: 'Genel İş Takip Listesi',
        icon: <RuleFolderIcon />,
        href: '/GenelIsTakipListesi'
      },
      {
        title: 'Stok Adeti',
        icon: <CloudUploadIcon />,
        href: '/StokAdetYukleme'
      },
      {
        title: 'Tur Planlama',
        icon: <TourIcon />,
        href: '/TurYonetimi'
      },
      {
        title: 'Birim Teslim Süresi',
        icon: <AccessAlarmIcon />,
        href: '/BirimTeslimSuresi'
      }
    ]
  }
]






