import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, TextField, Typography, Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { getDiscountedUser } from 'src/store/slices/dynamicCatalogSlice';
import { generalFollowActions } from 'src/store/slices/generalFollowSlice';
import { getUserRole } from 'src/utils/Common';
import AlertAndLoading from 'src/components/AlertAndLoading';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Segoe UI"
  },
  div: {
    fontWeight: 'bold',
    fontSize: '14px',
    width: '100%',
    color: 'red'
  },
  button: {
    color: '#FF9800',
    backgroundColor: '#424242',
    '&:hover': {
      color: '#FF9800',
      backgroundColor: '#424242'
    }
  }
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value || 0,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      allowNegative={false}
      isNumericString
      suffix="₺"
    />
  );
});

function PaidChange() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRole = getUserRole().Component
  const [expanded, setExpanded] = React.useState(true);

  const invoiceData = useSelector((state) => state.generalFollowSlice.invoiceDetail);
  const offerProductPrice = useSelector((state) => state.generalFollowSlice.updatedOfferPrice);
  const recomendedProductPrice = useSelector((state) => state.generalFollowSlice.updatedRecomendedPrice);
  const topModelDifferentPrice = useSelector((state) => state.generalFollowSlice.updatedTopModelDifferentPrice);
  const lastRequestTime = useSelector((state) => state.dynamicCatalogSlice.lastRequestGetDiscountedUser);

  const [recomendedPrice, setRecomendedPrice] = React.useState(invoiceData[0]?.Exchange?.RecomendedPrice);
  const [ABSPayAccount, setABSPayAccount] = React.useState(invoiceData[0]?.Exchange?.ABSPayAccount);
  const [topModelDiffPrice, setTopModelDifferentPrice] = React.useState(invoiceData[0]?.Exchange?.ProductRemainPrice);
  const [paidChangePrice, setPaidChangePrice] = React.useState('');
  const [buttonClicked, setButtonClicked] = React.useState(false);
  const [discountMessagePopUp, setDiscountMessagePopUp] = React.useState(false)
  const [discountMessageDsc, setDiscountMessageDsc] = React.useState('')
  const [responseMessage, setresponseMessage] = React.useState('');
  const [oderoPrice, setOderoPrice] = React.useState(invoiceData[0]?.Exchange?.OderoFuturesPrice ? invoiceData[0]?.Exchange?.OderoFuturesPrice : 0);
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });
  const [loading, setLoading] = React.useState(false);

  const [selectedDiscounted, setSelectedDiscounted] = React.useState('');
  const [discountedUserData, setDiscountedUserData] = React.useState([]);

  useEffect(() => {
    if (!lastRequestTime || moment(lastRequestTime).add(12, 'hours') <= moment()) {
      dispatch(getDiscountedUser()).then(response => {
        if (response.payload?.length > 0) {
          setDiscountedUserData(response.payload);
        }
      })
    }
  }, [dispatch]);

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }
  const handle5ButtonClick = () => {
    if (!buttonClicked) {
      getPercent5Discount();
      setDiscountMessagePopUp(true);
    }
    setButtonClicked(true);
  };

  const handle8ButtonClick = () => {
    if (!buttonClicked) {
      getPercent8Discount();
      setDiscountMessagePopUp(true);
    }
    setButtonClicked(true);
  };

  function newRecomendedPrice(val) {
    dispatch(generalFollowActions.updateRecomendedPrice(val))
    setRecomendedPrice(val)
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.RecomendedPrice = val;
    dispatch(generalFollowActions.updateNewData(tempData))
  }

  function newTopModelTotalPrice(val1) {
    dispatch(generalFollowActions.updateTopModelDifferentPrice(val1))
    setTopModelDifferentPrice(val1);
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    var total = parseFloat(val1) + parseFloat(offerProductPrice);
    tempData[0].Exchange.ProductRemainPrice = val1;
    tempData[0].Exchange.ProductTotalPrice = total;
    dispatch(generalFollowActions.updateNewData(tempData))


    if (val1 > 0) {
      if (!invoiceData[0]?.Exchange?.RequestedModelPrice || invoiceData[0]?.Exchange?.RequestedModelPrice !== 0) {//Talep edilen model varsa
        var verilecekUrünFiyat = +invoiceData[0]?.Exchange?.GiveProductPrice;
        var muadilUrunFiyat = +invoiceData[0]?.Exchange?.EqualProductPrice;
        var ustModelIndırımOranı = ((verilecekUrünFiyat - muadilUrunFiyat - val1) * 100 / (verilecekUrünFiyat - muadilUrunFiyat))
      }
      else {
        var verilecekUrünFiyat = +invoiceData[0]?.Exchange?.RequestedModelPrice;//Talep edilen model yoksa 
        var muadilUrunFiyat = +invoiceData[0]?.Exchange?.EqualProductPrice;
        var ustModelIndırımOranı = ((verilecekUrünFiyat - muadilUrunFiyat - val1) * 100 / (verilecekUrünFiyat - muadilUrunFiyat))
      }

      if (+ustModelIndırımOranı <= 0) {
        ustModelIndırımOranı = 0
      }

      dispatch(generalFollowActions.updateTopModelPriceDiscountRate(parseFloat(ustModelIndırımOranı.toFixed(2))))
    }
  }

  function newPaidChangeTotalPrice(val2) {
    dispatch(generalFollowActions.updateOfferPrice(val2))
    setPaidChangePrice(val2);
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    var total = parseFloat(topModelDifferentPrice) + parseFloat(val2);
    tempData[0].Exchange.Price = val2;
    tempData[0].Exchange.ProductTotalPrice = total;
    dispatch(generalFollowActions.updateNewData(tempData));
    if (val2 > 0) {
      var tavsiyeEdilenDegisimTutarı = +invoiceData[0]?.Exchange?.RecomendedPrice;
      var ucretliDegisimIndirimOranı = (1 - (val2 / tavsiyeEdilenDegisimTutarı)) * 100

      if (ucretliDegisimIndirimOranı < 0) {
        ucretliDegisimIndirimOranı = 0
      }
      dispatch(generalFollowActions.updateDiscountRate(parseFloat(ucretliDegisimIndirimOranı.toFixed(2))))

    }
  }

  function newPayAccount(val) {//ödeme sahibi
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    tempData[0].Exchange.ABSPayAccount = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setABSPayAccount(val);
  }
  function getPercent5Discount() {
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    var tavsiyeEdilenTutar = tempData[0].Exchange.RecomendedPrice
    var yüzdeBesIndırım = tavsiyeEdilenTutar - ((tavsiyeEdilenTutar * 5) / 100)
    tempData[0].Exchange.Price = yüzdeBesIndırım
    tempData[0].Exchange.DiscountControl = 1;
    dispatch(generalFollowActions.updateNewData(tempData));
    dispatch(generalFollowActions.updateOfferPrice(yüzdeBesIndırım))
    setPaidChangePrice(yüzdeBesIndırım);
  }
  function getPercent8Discount() {
    var tempData = JSON.parse(JSON.stringify(invoiceData))
    var tavsiyeEdilenTutar = tempData[0].Exchange.RecomendedPrice
    var yüzdeBesIndırım = tavsiyeEdilenTutar - ((tavsiyeEdilenTutar * 8) / 100)
    tempData[0].Exchange.Price = yüzdeBesIndırım
    tempData[0].Exchange.DiscountControl = 1;
    dispatch(generalFollowActions.updateNewData(tempData));
    dispatch(generalFollowActions.updateOfferPrice(yüzdeBesIndırım))
    setPaidChangePrice(yüzdeBesIndırım);
  }

  function DiscountDscControl() {
    if (!discountMessageDsc && discountMessageDsc == '') {
      InfoAlert('Lütfen indirim yapma sebebinizi giriniz.', 'error', 'Hata')
      return
    }
    dispatch(generalFollowActions.updateDiscountButtonMessage(discountMessageDsc))
    setDiscountMessagePopUp(false);
  }
  return (
    <>
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      <Accordion expanded={expanded} onChange={() => { setExpanded(!expanded); }} sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.div} variant="subtitle1">
            Fiyat İşlemleri
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <TextField
                  label="Tavsiye edilen Değişim Ücreti"
                  name="numberformat"
                  id="formatted-numberformat-input"
                  size="small"
                  fullWidth
                  disabled
                  value={recomendedProductPrice}
                  onChange={(event) => { newRecomendedPrice(event.target.value); }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title="% İndirimli Fiyat">
                  <TextField
                    id="input-with-icon-textfield"
                    label="İndirimli Fiyat"
                    size="small"
                    fullWidth
                    variant="outlined"
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <TextField
                  label="Ücretli Değişim Tutarı"
                  name="numberformat"
                  id="formatted-numberformat-input"
                  size="small"
                  fullWidth
                  value={offerProductPrice}
                  onChange={(event) => { newPaidChangeTotalPrice(event.target.value); }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Autocomplete
                  size="small"
                  fullWidth
                  options={discountedUserData || []}
                  value={selectedDiscounted || null}
                  getOptionLabel={(option) => (option.NAME_SURNAME ? option.NAME_SURNAME + ' (' + option.IDENTITY_NO + ')' : '')}
                  renderInput={(params) => <TextField {...params} label="İndirim Talep Eden Kişi" variant="outlined" />}
                  onChange={(event, value) => { setSelectedDiscounted(value); }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <TextField
                  label="Üst Model Fark Tutarı"
                  name="numberformat"
                  id="formatted-numberformat-input"
                  size="small"
                  fullWidth
                  value={topModelDifferentPrice}
                  onChange={(event) => { newTopModelTotalPrice(event.target.value) }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title="Dekont Sahibi">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Dekont Sahibi"
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={ABSPayAccount}
                    onChange={(event) => { newPayAccount(event.target.value); }}
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          {/* <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Tooltip title="Vade Farkı Tutarı">
                  <TextField
                    id="input-with-icon-textfield"
                    label="Vade Farkı Tutarı"
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={oderoPrice}
                    onChange={(event) => { setOderoPrice(event.target.value); }}
                    disabled
                  />
                </Tooltip>
              </Box>
            </Grid>
          </Grid> */}
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <TextField
                  label="Toplam Tutar"
                  name="numberformat"
                  id="formatted-numberformat-input"
                  size="small"
                  sx={{
                    "& .css-u9osun.Mui-disabled": {
                      color: "red"
                    }, ".css-1o6z5ng.Mui-disabled": {
                      WebkitTextFillColor: "red"
                    }
                  }}
                  fullWidth
                  disabled
                  value={parseFloat(topModelDifferentPrice) + parseFloat(offerProductPrice)} //  + parseFloat(oderoPrice) eklenecek.
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              {(userRole?.filter(i => i.COMPONENT_NAME == "Percent5Discount").length > 0) ? (
                <Box sx={{ "& > :not(style)": { m: 1 } }}>
                  <Button
                    fullWidth
                    size="medium"
                    sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                    className={classes.button}
                    onClick={handle5ButtonClick}
                    disabled={invoiceData[0]?.Exchange.DiscountControl == 1 ? true : false}
                  >
                    %5 indirim
                  </Button>
                </Box>
              ) : ((userRole?.filter(i => i.COMPONENT_NAME == "Percent8Discount").length > 0) ? (
                <Box sx={{ "& > :not(style)": { m: 1 } }}>
                  <Button
                    fullWidth
                    size="medium"
                    className={classes.button}
                    onClick={handle8ButtonClick}
                    disabled={invoiceData[0]?.Exchange.DiscountControl == 1 ? true : false}
                  >
                    %8 indirim
                  </Button>
                </Box>
              ) : null
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Dialog
        open={discountMessagePopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <Grid item xs={12} md={12} >
            <TextField
              id="input-with-icon-textfield"
              label="Lütfen indirim yapma sebebinizi giriniz."
              size="small"
              value={discountMessageDsc}
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              onChange={(event) => { setDiscountMessageDsc(event.target.value) }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            sx={{ color: '#FF9800', backgroundColor: '#424242', marginRight: '0.5 rem' }}
            onClick={() => { DiscountDscControl(); }}
          >INDIRIM YAP</Button>
          <IconButton
            color="primary"
            onClick={() => setDiscountMessagePopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PaidChange;

