import AddIcon from '@mui/icons-material/Add';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Tooltip, Card, Box, Badge, CardActionArea, CardActions, CardContent, Chip, CircularProgress, DialogContent, Grid, IconButton, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { DataGrid, trTR } from '@mui/x-data-grid';
import trLocale from 'date-fns/locale/tr';
import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRole } from 'src/utils/Common';
import Page from 'src/components/Page';
import {
  getChangeReasons, getCities, getConfirmTypes, getCustomerTypes, getJobPoolOrder, getJobTail, getModelCode, getProducter, getProducts, getProvince, getRegions, getStatuses, getSubStatuses, getTailList, getTailUsers, getWarrantyType, setJobTail, updateBusyPeriodOrder,
  updateJobTail
} from 'src/store/slices/assigmentSlice';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AlertAndLoading from 'src/components/AlertAndLoading';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Segoe UI",
    width: '100%',
    height: '100%'
  },
  cards: {
    minHeight: 200,
    height: '100%'
  },
  cardsContent: {
    minHeight: 60
  },
  generalCards: {
    minheight: 800,
    width: '100%',
    height: '100%'
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  buttons: {
    "& .css-u1865r-MuiButtonBase-root-MuiButton-root:hover": {
      backgroundColor: '#424242'
    },
  }
}));

function Assignment() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRole = getUserRole().RoleId


  const regions = useSelector((state) => state.assigmentSlice.regions);
  const cities = useSelector((state) => state.assigmentSlice.cities);
  const products = useSelector((state) => state.assigmentSlice.products);
  const statuses = useSelector((state) => state.assigmentSlice.statuses);
  const changeReasons = useSelector((state) => state.assigmentSlice.changeReasons);
  const confirmTypes = useSelector((state) => state.assigmentSlice.confirmTypes);
  const subStatuses = useSelector((state) => state.assigmentSlice.subStatuses);
  const customerTypes = useSelector((state) => state.assigmentSlice.customerTypes);
  const producters = useSelector((state) => state.assigmentSlice.producters);
  const provinces = useSelector((state) => state.assigmentSlice.provinces);
  const warrantyTypes = useSelector((state) => state.assigmentSlice.warrantyTypes);


  const [searchName, setSearchName] = React.useState('')
  const [searchNameList, setSearchNameList] = React.useState('')
  const [region, setRegion] = React.useState([]);
  const [city, setCity] = React.useState([]);
  const [productGroup, setProductGroup] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [changeReason, setChangeReason] = React.useState([]);
  const [confirmType, setConfirmType] = React.useState([]);
  const [subStatus, setSubStatus] = React.useState([]);
  const [customerType, setCustomerType] = React.useState([]);
  const [productionPeriod, setProductionPeriod] = React.useState(null);
  const [productionYear, setProductionYear] = React.useState(null);
  const [refundStatus, setRefundStatus] = React.useState(null);
  const [sendToMail, setSendToMail] = React.useState(null);
  const [exChangeProductStatus, setExChangeProductStatus] = React.useState(null);
  const [tailTitle, setTailTitle] = React.useState('');
  const [userModelCodeSearch, setUserModelCodeSearch] = React.useState([]);
  const [producter, setProducter] = React.useState([]);
  const [warrantyType, setWarrantyType] = React.useState([]);
  const [province, setProvince] = React.useState([]);
  const [userInfo, setUserInfo] = React.useState([]);
  const [selectedModelCodeLeft, setSelectedModelCodeLeft] = React.useState([]);
  const [selectedModelCodeRight, setSelectedModelCodeRight] = React.useState([]);
  const [selectedUserPreOrderRight, setSelectedUserPreOrderRight] = React.useState([]);
  const [modelCodeInfo, setModelCodeInfo] = React.useState([]);
  const [usersPreOrder, setUsersPreOrder] = React.useState([]);
  const [refrigerator, setRefrigerator] = React.useState([]);
  const [combi, setCombi] = React.useState([]);
  const [callDate, setCallDate] = React.useState([]);
  const [warmer, setWarmer] = React.useState([]);
  const [callRepeat, setCallRepeat] = React.useState([]);
  const [airConditioner, setAirConditioner] = React.useState([]);
  const [userAddPopUp, setUserAddPopUp] = React.useState(false);
  const [modelCodePopUp, setModelCodePopUp] = React.useState(false);
  const [tailUserPopUp, setTailUserPopUp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedUserPreOrder, setSelectedUserPreOrder] = React.useState([]);
  const [jobTails, setJobTails] = React.useState([]);
  const [tailUserList, setTailUserList] = React.useState([]);
  const [uyariTip, setUyariTip] = React.useState();
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [mainId, setMainId] = React.useState('');
  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({ ErrorType: 'error', ErrorTitle: 'Uyarı' });

  const [expandedAccordion, setExpandedAccordion] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageTailList, setPageTailList] = React.useState(0);
  const [rowsPerPageTailList, setRowsPerPageTailList] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangePageUserList = (event, newPage) => {
    setPageTailList(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangeRowsPerPageTailList = (event) => {
    setRowsPerPageTailList(+event.target.value)
    setPageTailList(0);
  };

  const handleChangeProductionYear = e => {

    if (!productionYear) {
      setProductionYear(new Date(e).getUTCFullYear().toString());

      return;
    }
    if (e?.getUTCFullYear() == productionYear) {
      setProductionYear(null);
    }
    else {
      setProductionYear(new Date(e).getUTCFullYear().toString());
    }
  }
  const temp = [{ Id: 1, Value: '1' },
  { Id: 2, Value: '2' },
  { Id: 3, Value: '3' },
  { Id: 4, Value: '4' },
  { Id: 5, Value: '5' },
  { Id: 6, Value: '6' },
  { Id: 7, Value: '7' },
  { Id: 8, Value: '8' },
  { Id: 9, Value: '9' },
  { Id: 10, Value: '10' },
  { Id: 11, Value: '11' },
  { Id: 12, Value: '12' },
  ]

  useEffect(() => {
    setLoading(true)
    dispatch(getJobTail()).then(response => {
      setJobTails(response.payload);
      setLoading(false)
    });
    dispatch(getJobPoolOrder()).then(response => {
      const data = response.payload;
      setRefrigerator(data?.filter(i => i.ORDER_ID == 1)[0]?.POOLORDER)
      setAirConditioner(data?.filter(i => i.ORDER_ID == 2)[0]?.POOLORDER)
      setCombi(data?.filter(i => i.ORDER_ID == 3)[0]?.POOLORDER)
      setWarmer(data?.filter(i => i.ORDER_ID == 4)[0]?.POOLORDER)
      setCallRepeat(data?.filter(i => i.ORDER_ID == 5)[0]?.POOLORDER)
      setCallDate(data?.filter(i => i.ORDER_ID == 6)[0]?.POOLORDER)
    });
    dispatch(getRegions());
    dispatch(getCities());
    dispatch(getProducts());
    dispatch(getStatuses());
    dispatch(getChangeReasons());
    dispatch(getConfirmTypes());
    dispatch(getSubStatuses());
    dispatch(getCustomerTypes());
    dispatch(getProducter());
    dispatch(getProvince());
    dispatch(getWarrantyType());
  }, [dispatch]);

  async function usersGet() {
    setUserAddPopUp(true);
    setLoading(true)
    dispatch(getTailUsers()).then(response => {
      if (response.payload?.length > 0) {
        setUserInfo(response.payload?.map((W) => ({
          UserId: W.ID,
          IdentityNo: W.SICILNO,
          NameSurname: W.ADSOYAD
        })));
      }
      setLoading(false)
    })
  }

  async function ModelCodeGet() {
    setModelCodeInfo([])
    if (userModelCodeSearch.length >= 3) {
      const data = {
        ItemId: '16',
        ItemValue: userModelCodeSearch
      }
      setLoading(true)
      dispatch(getModelCode(data)).then(response => {
        if (response.payload?.length > 0) {
          setModelCodeInfo(response.payload?.map((W) => ({
            Id: W.Id,
            UserModelCode: W.UserModelCode,
            Dsc: W.Dsc
          })));
        }
        setLoading(false)
      })
    }
    else {
      // setresponseMessage({
      //   ErrorCode: 1000,
      //   ErrorDescription: ('Lütfen Aramak İstediğiniz Model Kodunun 3 Karakterini Yazınız.')
      // });
      // setUyari(true);
      InfoAlert('Lütfen Aramak İstediğiniz Model Kodunun 3 Karakterini Yazınız.', 'warning', 'Uyarı');
      return;
    }
  }

  useEffect(() => {
  }, [usersPreOrder]);


  async function SaveJobTail() {

    if (!tailTitle) {
      InfoAlert('Lütfen kuyruk tanımı giriniz...', 'error', 'Hata');
      return;
    }
    if (selectedUserPreOrderRight.length == 0) {
      InfoAlert('Lütfen kuyruk oluşturmadan önce kullanıcı ekleyiniz....', 'error', 'Hata');
      return;
    }
    setLoading(true)

    var year = productionYear ? new Date(productionYear).getUTCFullYear() : null;
    const data = {
      Description: tailTitle,
      Item1: region?.map(i => { return i.Id }).join(',') || null,
      Item2: city?.map(i => { return i.Id }).join(',') || null,
      Item3: status?.map(i => { return i.Id }).join(',') || null,
      Item4: changeReason?.map(i => { return i.Id }).join(',') || null,
      Item5: customerType?.map(i => { return i.Id }).join(',') || null,
      Item6: confirmType?.map(i => { return i.Id }).join(',') || null,
      Item7: productGroup?.map(i => { return i.Id }).join(',') || null,
      Item8: subStatus?.map(i => { return i.Id }).join(',') || null,
      Item9: producter?.map(i => { return i.Id }).join(',') || null,
      Item10: province?.map(i => { return i.Id }).join(',') || null,
      Item11: warrantyType?.map(i => { return i.Id }).join(',') || null,
      Item12: exChangeProductStatus?.Id.toString() || null,
      Item13: refundStatus?.Id.toString() || null,
      Item14: year?.toString() || null,
      Item15: productionPeriod?.Id.toString() || null,
      Item16: selectedModelCodeRight?.map(i => { return i.UserModelCode }).join(',') || null,
      Item17: sendToMail?.Id.toString() || null,
      Users: selectedUserPreOrderRight?.map(i => { return i.UserId }).join(',') || null,
      PreOrder: selectedUserPreOrderRight?.map(i => { return i.PreOrder }).join(',') || null,
    }
    dispatch(setJobTail(data)).then(response => {
      clearForm()
      if (response.payload.ErrorCode == 0) {
        InfoAlert(response.payload.ErrorDescription, 'success', 'Başarılı');
        dispatch(getJobTail()).then(response => {
          setJobTails(response.payload);
          setLoading(false)
        })
      }
      else {
        InfoAlert(response.payload.ErrorDescription, 'error', 'Hata');
        setLoading(false)
      }
      setLoading(false)
    })
  }
  async function selectedUsers() {
    debugger
    const temp = usersPreOrder.filter(i => i.value != undefined)
    var temp1 = [].concat(selectedUserPreOrderRight)
    temp.map(e => {
      temp1 = temp1.filter(i => i.UserId != e.user)
      temp1.push({ ...userInfo.filter(i => i.UserId == e.user)[0], PreOrder: usersPreOrder.filter(i => i.user == e.user)[0].value })
      console.log(e)
    })
    setSelectedUserPreOrderRight(temp1);
    console.log(selectedUserPreOrderRight)
    console.log(temp1)

    setUsersPreOrder([])

  }


  function deleteConfirm(MainId) {
    confirmAlert({
      message: ('Silmek istediğinize emin misiniz?'),
      buttons: [
        {
          label: ('Evet'),
          onClick: () => deleteJobTail(MainId)
        },
        {
          label: ('Hayır'),
          onClick: () => { }
        }
      ]
    });
  }

  function deleteJobTail(MainId) {
    setLoading(true)
    const data = {
      MainId: MainId,
      Delete: 1
    }
    dispatch(updateJobTail(data)).then(response => {
      dispatch(getJobTail()).then(response => {
        setJobTails(response.payload);
        setLoading(false)
      })
    })
    clearForm();
  }

  function tailList() {
    setTailUserPopUp(true);
    setLoading(true)
    dispatch(getTailList()).then(response => {
      setTailUserList(response.payload)
      setLoading(false)
    })
  }

  function updateJobTailForm(row) {
    clearForm()
    setIsUpdate(true)
    const region = regions.filter(i => { return Array.from(row.MainArea?.filter(r => r.MAIN_ID == row.MainId && r.AREA_ID == 1).map(k => { return k.SELECTED }) || [], Number).includes(i.Id) })
    const city = cities.filter(i => { return Array.from(row.MainArea?.filter(r => r.MAIN_ID == row.MainId && r.AREA_ID == 2).map(k => { return k.SELECTED }) || [], Number).includes(i.Id) })
    const status = statuses.filter(i => { return Array.from(row.MainArea?.filter(r => r.MAIN_ID == row.MainId && r.AREA_ID == 3).map(k => { return k.SELECTED }) || [], Number).includes(i.Id) })
    const changeReason = changeReasons.filter(i => { return Array.from(row.MainArea?.filter(r => r.MAIN_ID == row.MainId && r.AREA_ID == 4).map(k => { return k.SELECTED }) || [], Number).includes(i.Id) })
    const customerType = customerTypes.filter(i => { return Array.from(row.MainArea?.filter(r => r.MAIN_ID == row.MainId && r.AREA_ID == 5).map(k => { return k.SELECTED }) || [], Number).includes(i.Id) })
    const confirmType = confirmTypes.filter(i => { return Array.from(row.MainArea?.filter(r => r.MAIN_ID == row.MainId && r.AREA_ID == 6).map(k => { return k.SELECTED }) || [], Number).includes(i.Id) })
    const productGroup = products.filter(i => { return Array.from(row.MainArea?.filter(r => r.MAIN_ID == row.MainId && r.AREA_ID == 7).map(k => { return k.SELECTED }) || [], Number).includes(i.Id) })
    const subStatus = subStatuses.filter(i => { return Array.from(row.MainArea?.filter(r => r.MAIN_ID == row.MainId && r.AREA_ID == 8).map(k => { return k.SELECTED }) || [], Number).includes(i.Id) })
    const producter = producters.filter(i => { return Array.from(row.MainArea?.filter(r => r.MAIN_ID == row.MainId && r.AREA_ID == 9).map(k => { return k.SELECTED }) || [], Number).includes(i.Id) })
    const province = provinces.filter(i => { return Array.from(row.MainArea?.filter(r => r.MAIN_ID == row.MainId && r.AREA_ID == 10).map(k => { return k.SELECTED }) || [], Number).includes(i.Id) })
    const warrantyType = warrantyTypes.filter(i => { return Array.from(row.MainArea?.filter(r => r.MAIN_ID == row.MainId && r.AREA_ID == 11).map(k => { return k.SELECTED }) || [], Number).includes(i.Id) })
    var userRight = row.Users?.map(i => { return { IdentityNo: i.SICILNO, NameSurname: i.ADSOYAD, PreOrder: i.PREORDER, UserId: i.ID } })
    const user = row.Users?.map(i => { return { user: i.ID, value: i.PREORDER } })
    const modelCodeRight = row.MainArea?.filter(i => i.MAIN_ID == row.MainId && i.AREA_ID == 16)?.map(i => { return { Id: i.SELECTED, UserModelCode: i.SELECTED, Dsc: i.ProductDescription } })
    setTailTitle(row.Description)
    setRegion(region)
    setCity(city)
    setStatus(status)
    setChangeReason(changeReason)
    setCustomerType(customerType)
    setConfirmType(confirmType)
    setProductGroup(productGroup)
    setProducter(producter)
    setProvince(province)
    setWarrantyType(warrantyType)
    setSubStatus(subStatus)
    setExChangeProductStatus(parseInt(row.MainArea?.filter(i => i.MAIN_ID == row.MainId && i.AREA_ID == 12)[0]?.SELECTED) === 1 ? { Id: 1, Value: 'İade Edildi' } : null)
    setRefundStatus(parseInt(row.MainArea?.filter(i => i.MAIN_ID == row.MainId && i.AREA_ID == 13)[0]?.SELECTED) === 1 ? { Id: 1, Value: 'Teslim Alındı' } : null)
    setProductionYear(row.MainArea?.filter(i => i.MAIN_ID == row.MainId && i.AREA_ID == 14)[0]?.SELECTED || null)
    setProductionPeriod(temp.find(a => a.Value == row.MainArea?.filter(i => i.MAIN_ID == row.MainId && i.AREA_ID == 15)[0]?.SELECTED) || null)
    setSendToMail(parseInt(row.MainArea?.filter(i => i.MAIN_ID == row.MainId && i.AREA_ID == 17)[0]?.SELECTED) === 1 ? { Id: 1, Value: 'Mail Gönder' } : { Id: 0, Value: 'Mail Gönderme' })
    setSelectedModelCodeRight(modelCodeRight)
    setSelectedUserPreOrder(user)
    setSelectedUserPreOrderRight(userRight)
  }
  function updateJobTailRow() {
    if (!tailTitle) return;
    setLoading(true);
    var year = productionYear ? new Date(productionYear).getUTCFullYear() : null;
    const data = {
      MainId: mainId,
      Description: tailTitle,
      Item1: region?.map(i => { return i.Id }).join(',') || null,
      Item2: city?.map(i => { return i.Id }).join(',') || null,
      Item3: status?.map(i => { return i.Id }).join(',') || null,
      Item4: changeReason?.map(i => { return i.Id }).join(',') || null,
      Item5: customerType?.map(i => { return i.Id }).join(',') || null,
      Item6: confirmType?.map(i => { return i.Id }).join(',') || null,
      Item7: productGroup?.map(i => { return i.Id }).join(',') || null,
      Item8: subStatus?.map(i => { return i.Id }).join(',') || null,
      Item9: producter?.map(i => { return i.Id }).join(',') || null,
      Item10: province?.map(i => { return i.Id }).join(',') || null,
      Item11: warrantyType?.map(i => { return i.Id }).join(',') || null,
      Item12: exChangeProductStatus?.Id.toString() || null,
      Item13: refundStatus?.Id.toString() || null,
      Item14: year?.toString() || null,
      Item15: productionPeriod?.Id.toString() || null,
      Item16: selectedModelCodeRight?.map(i => { return i.UserModelCode }).join(',') || null,
      Item17: sendToMail?.Id.toString() || null,
      Users: selectedUserPreOrderRight?.map(i => { return i.UserId }).join(',') || null,
      PreOrder: selectedUserPreOrderRight?.map(i => { return i.PreOrder }).join(',') || null,
    }
    dispatch(updateJobTail(data)).then(response => {
      clearForm();
      if (response.payload.ErrorCode == 0) {
        InfoAlert(response.payload.ErrorDescription, 'success', 'Başarılı');
        dispatch(getJobTail()).then(response => {
          setJobTails(response.payload);
          setLoading(false)
        })
      }
      else {
        InfoAlert(response.payload.ErrorDescription, 'error', 'Hata');
        setLoading(false)
      }
      setLoading(false)
    })
  }

  function clearForm() {
    setRegion([])
    setCity([])
    setStatus([])
    setChangeReason([])
    setCustomerType([])
    setConfirmType([])
    setProductGroup([])
    setCustomerType([])
    setProducter([])
    setProvince([])
    setWarrantyType([])
    setExChangeProductStatus(null)
    setRefundStatus(null)
    setProductionYear(null)
    setProductionPeriod(null)
    setSendToMail(null)
    setSelectedModelCodeRight([])
    setSelectedUserPreOrder([])
    setSelectedUserPreOrderRight([])
    setSubStatus([])
    setTailTitle('')
    setIsUpdate(false)
  }

  function updateBusyPeriod() {
    setLoading(true);
    const data = {
      Item1: refrigerator.toString() || null,
      Item2: airConditioner.toString() || null,
      Item3: combi.toString() || null,
      Item4: warmer.toString() || null,
      Item5: callRepeat.toString() || null,
      Item6: callDate.toString() || null
    }

    dispatch(updateBusyPeriodOrder(data)).then(response => {
      if (response.payload.ErrorCode == 0) {
        InfoAlert(response.payload.ErrorDescription, 'success', 'Başarılı');
        setLoading(false)
      }
      else {
        InfoAlert(response.payload.ErrorDescription, 'error', 'Hata');
        setLoading(false)
      }
      setLoading(false)
      return;
    })
  }

  function removeUserRight(UserId) {
    var temp = [...selectedUserPreOrderRight]
    var templist = temp.findIndex(i => i.UserId == UserId);
    temp.splice(templist, 1)
    setSelectedUserPreOrderRight(temp)
  }
  function removeModelCodeRight(cellValues) {
    var temp = [...selectedModelCodeRight]
    var templist = temp.findIndex(i => i.Id == cellValues.row.Id);
    temp.splice(templist, 1)
    setSelectedModelCodeRight(temp)

  }
  const modelCodeColumnsLeft = [
    { field: 'UserModelCode', headerName: 'Kullanıcı Model Kodu', width: 250 },
    { field: 'Dsc', headerName: 'Açıklama', width: 350 }
  ];

  const modelCodeColumnsRight = [
    { field: 'UserModelCode', headerName: 'Kullanıcı Model Kodu', width: 250 },
    { field: 'Dsc', headerName: 'Açıklama', width: 350 },
    {
      field: 'Id',
      renderCell: (cellValues) => {
        return (
          <Grid item xs={3}>
            <Stack spacing={4} direction="row">
              <Button
                fullWidth
                size="Small"
                variant="outlined"
                onClick={(event) => { removeModelCodeRight(cellValues) }}
              >Çıkar</Button>
            </Stack>
          </Grid>
        );

      }
      , width: 100
    }
  ];

  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    })
    setUyari(true);
    return
  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  return (
    <Page className={classes.root} title="Dia Atama Ekranı">
      <AlertAndLoading Message={responseMessage} open={uyari} AlertType={alertType} onClose={uyariKapat} loading={loading} />
      <Card className={classes.generalCards}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              Kuyruk Atama
            </Grid>
            <Grid item xs={3} md={3}>
              <Button
                fullWidth
                size="small"
                sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                onClick={() => { tailList() }}
              >Kuyruk Kullanıcı Listesi</Button>
            </Grid>
            <Grid item xs={3} md={3}>
              <Button
                fullWidth
                size="small"
                className={classes.button}
                sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                onClick={() => { clearForm() }}
              >Sıfırla</Button>
            </Grid>
          </Grid>
          <Typography sx={{ padding: '1%', width: '100%', fontWeight: 'bold' }} variant="subtitle1">


          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                label="Kuyruk Tanımı"
                id="outlined-start-adornment"
                value={tailTitle}
                onChange={(event) => { setTailTitle(event.target.value); }}
                fullWidth
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">Kuyruk Tanımı</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                multiple
                limitTags={1}
                id="multiple-limit-tags"
                options={regions}
                getOptionLabel={(option) => option.Dsc}
                size="small"
                fullWidth
                value={region}
                onChange={(event, newValue) => { setRegion(newValue); }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Dsc}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Bölge" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                multiple
                limitTags={1}
                id="multiple-limit-tags"
                options={cities}
                value={city}
                getOptionLabel={(option) => option.Dsc}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setCity(newValue); }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Dsc}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Servis" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                multiple
                limitTags={1}
                id="multiple-limit-tags"
                options={products}
                value={productGroup}
                getOptionLabel={(option) => option.Dsc}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setProductGroup(newValue); }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Dsc}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Ürün Grubu" placeholder="Ürün Grubu" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                multiple
                limitTags={1}
                id="multiple-limit-tags"
                options={statuses}
                value={status}
                getOptionLabel={(option) => option.Dsc}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setStatus(newValue); }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Dsc}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Durum" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                multiple
                limitTags={1}
                id="multiple-limit-tags"
                options={changeReasons}
                value={changeReason}
                getOptionLabel={(option) => option.Dsc}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setChangeReason(newValue); }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Dsc}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Değişim Nedeni" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                multiple
                limitTags={1}
                id="multiple-limit-tags"
                options={confirmTypes}
                value={confirmType}
                getOptionLabel={(option) => option.Dsc}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setConfirmType(newValue); }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Dsc}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Onay Tipi" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                multiple
                limitTags={1}
                id="multiple-limit-tags"
                options={subStatuses}
                value={subStatus}
                getOptionLabel={(option) => option.Dsc}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setSubStatus(newValue); }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Dsc}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Alt Durum" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                multiple
                limitTags={1}
                id="multiple-limit-tags"
                options={customerTypes}
                value={customerType}
                getOptionLabel={(option) => option.Dsc}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setCustomerType(newValue); }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Dsc}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Müşteri Tipi" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                limitTags={1}
                id="multiple-limit-tags"
                options={
                  [{ Id: 1, Value: 'İade Edildi' }]
                }
                value={exChangeProductStatus}
                getOptionLabel={(option) => option.Value}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setExChangeProductStatus(newValue) }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Value}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Eski Ürün Durumu" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                limitTags={1}
                id="multiple-limit-tags"
                options={
                  [{ Id: 1, Value: 'Teslim Alındı' }]
                }
                value={refundStatus}
                getOptionLabel={(option) => option.Value}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setRefundStatus(newValue) }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Value}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Para İadesi Durumu" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                multiple
                limitTags={1}
                id="multiple-limit-tags"
                options={producters}
                value={producter}
                getOptionLabel={(option) => option.Dsc}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setProducter(newValue); }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Dsc}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Alınan Ürünün Üretici" variant="outlined" />
                )} />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                multiple
                limitTags={1}
                id="multiple-limit-tags"
                options={warrantyTypes}
                value={warrantyType}
                getOptionLabel={(option) => option.Dsc}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setWarrantyType(newValue); }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Dsc}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Garanti Tipi" variant="outlined" />
                )} />
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
                <Stack spacing={3}>
                  <DatePicker
                    views={['year']}
                    label="Üretim Yılı"
                    value={productionYear}
                    format={'dd.MM.yyyy'}
                    onChange={(e) => {
                      handleChangeProductionYear(e);
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} size="small" />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                limitTags={1}
                id="multiple-limit-tags"
                options={temp}
                value={productionPeriod}
                getOptionLabel={(option) => option.Value}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setProductionPeriod(newValue) }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    {option.Value}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Üretim Dönemi" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                limitTags={1}
                id="multiple-limit-tags"
                options={
                  [{ Id: 1, Value: 'Mail Gönder' },
                  { Id: 0, Value: 'Mail Gönderme' }]
                }
                value={sendToMail}
                getOptionLabel={(option) => option.Value}
                size="small"
                fullWidth
                onChange={(event, newValue) => { setSendToMail(newValue) }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.Value}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Mail Gönder" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <Button
                fullWidth
                size="small"
                variant="outlined"
                sx={{ color: '#FF9800' }}
                onClick={() => { setModelCodePopUp(true); setUserModelCodeSearch(''); }}
              >Model Kodu</Button>
            </Grid>
            <Grid item xs={2} md={2}>
              <Stack spacing={4} direction="row">
                <Button
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{ color: '#FF9800' }}
                  onClick={() => { usersGet(); }}
                >Kullanıcı Ekle</Button>
              </Stack>
            </Grid>
            <Grid item xs={8} md={9}></Grid>
            {userRole?.filter(i => i.ROLE_ID == 2).length > 0 || userRole?.filter(i => i.ROLE_ID == 4).length || userRole?.filter(i => i.ROLE_ID == 5).length > 0 ?
              <Grid item xs={4} lg={3}>
                <Button
                  fullWidth
                  size="small"
                  className={classes.buttons}
                  sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                  onClick={() => {
                    if (!isUpdate) SaveJobTail();
                    else updateJobTailRow();
                  }}
                >{isUpdate ? 'Kuyruk Güncelle' : 'Kuyruk Ekle'}</Button>
              </Grid> : null}
            <Grid item xs={12} md={12} >
              <Accordion expanded={expandedAccordion === 'panel2'} onChange={(event, isExpanded) => setExpandedAccordion(isExpanded ? "panel2" : false)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }} variant="inherit">
                    İş Atama Önceliklendirme
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={1}>
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label="Buzdolabı"
                        value={refrigerator}
                        onChange={(event) => { setRefrigerator(event.target.value); }}
                        variant="outlined"
                      >
                        <MenuItem key={0} value={0} />
                        <MenuItem key={1} value={1}>{1}</MenuItem>
                        <MenuItem key={2} value={2}>{2}</MenuItem>
                        <MenuItem key={3} value={3}>{3}</MenuItem>
                        <MenuItem key={4} value={4}>{4}</MenuItem>
                        <MenuItem key={5} value={5}>{5}</MenuItem>
                        <MenuItem key={6} value={6}>{6}</MenuItem>
                        <MenuItem key={7} value={7}>{7}</MenuItem>
                        <MenuItem key={8} value={8}>{8}</MenuItem>
                        <MenuItem key={9} value={9}>{9}</MenuItem>
                        <MenuItem key={10} value={10}>{10}</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label="Klima"
                        value={airConditioner}
                        onChange={(event) => { setAirConditioner(event.target.value); }}
                        variant="outlined"
                      >
                        <MenuItem key={0} value={0} />
                        <MenuItem key={1} value={1}>{1}</MenuItem>
                        <MenuItem key={2} value={2}>{2}</MenuItem>
                        <MenuItem key={3} value={3}>{3}</MenuItem>
                        <MenuItem key={4} value={4}>{4}</MenuItem>
                        <MenuItem key={5} value={5}>{5}</MenuItem>
                        <MenuItem key={6} value={6}>{6}</MenuItem>
                        <MenuItem key={7} value={7}>{7}</MenuItem>
                        <MenuItem key={8} value={8}>{8}</MenuItem>
                        <MenuItem key={9} value={9}>{9}</MenuItem>
                        <MenuItem key={10} value={10}>{10}</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label="Kombi"
                        value={combi}
                        onChange={(event) => { setCombi(event.target.value); }}
                        variant="outlined"
                      >
                        <MenuItem key={0} value={0} />
                        <MenuItem key={1} value={1}>{1}</MenuItem>
                        <MenuItem key={2} value={2}>{2}</MenuItem>
                        <MenuItem key={3} value={3}>{3}</MenuItem>
                        <MenuItem key={4} value={4}>{4}</MenuItem>
                        <MenuItem key={5} value={5}>{5}</MenuItem>
                        <MenuItem key={6} value={6}>{6}</MenuItem>
                        <MenuItem key={7} value={7}>{7}</MenuItem>
                        <MenuItem key={8} value={8}>{8}</MenuItem>
                        <MenuItem key={9} value={9}>{9}</MenuItem>
                        <MenuItem key={10} value={10}>{10}</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label="Isıtıcılar"
                        value={warmer}
                        onChange={(event) => { setWarmer(event.target.value); }}
                        variant="outlined"
                      >
                        <MenuItem key={0} value={0} />
                        <MenuItem key={1} value={1}>{1}</MenuItem>
                        <MenuItem key={2} value={2}>{2}</MenuItem>
                        <MenuItem key={3} value={3}>{3}</MenuItem>
                        <MenuItem key={4} value={4}>{4}</MenuItem>
                        <MenuItem key={5} value={5}>{5}</MenuItem>
                        <MenuItem key={6} value={6}>{6}</MenuItem>
                        <MenuItem key={7} value={7}>{7}</MenuItem>
                        <MenuItem key={8} value={8}>{8}</MenuItem>
                        <MenuItem key={9} value={9}>{9}</MenuItem>
                        <MenuItem key={10} value={10}>{10}</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label="Arama Tekrar Sayısı"
                        value={callRepeat}
                        onChange={(event) => { setCallRepeat(event.target.value); }}
                        variant="outlined"
                      >
                        <MenuItem key={0} value={0} />
                        <MenuItem key={1} value={1}>{1}</MenuItem>
                        <MenuItem key={2} value={2}>{2}</MenuItem>
                        <MenuItem key={3} value={3}>{3}</MenuItem>
                        <MenuItem key={4} value={4}>{4}</MenuItem>
                        <MenuItem key={5} value={5}>{5}</MenuItem>
                        <MenuItem key={6} value={6}>{6}</MenuItem>
                        <MenuItem key={7} value={7}>{7}</MenuItem>
                        <MenuItem key={8} value={8}>{8}</MenuItem>
                        <MenuItem key={9} value={9}>{9}</MenuItem>
                        <MenuItem key={10} value={10}>{10}</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label="Çağrı Tarihi"
                        value={callDate}
                        onChange={(event) => { setCallDate(event.target.value); }}
                        variant="outlined"
                      >
                        <MenuItem key={0} value={0} />
                        <MenuItem key={1} value={1}>{1}</MenuItem>
                        <MenuItem key={2} value={2}>{2}</MenuItem>
                        <MenuItem key={3} value={3}>{3}</MenuItem>
                        <MenuItem key={4} value={4}>{4}</MenuItem>
                        <MenuItem key={5} value={5}>{5}</MenuItem>
                        <MenuItem key={6} value={6}>{6}</MenuItem>
                        <MenuItem key={7} value={7}>{7}</MenuItem>
                        <MenuItem key={8} value={8}>{8}</MenuItem>
                        <MenuItem key={9} value={9}>{9}</MenuItem>
                        <MenuItem key={10} value={10}>{10}</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={2}>
                      <Stack spacing={4} direction="row">
                        <Button
                          fullWidth
                          size="medium"
                          sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                          onClick={() => { updateBusyPeriod(); }}
                        >Güncelle</Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={12} style={{ paddingTop: 50 }}></Grid>
            {jobTails?.map((row, index) => (
              <Grid item xs={3} md={3} key={index}>
                <Card className={classes.cards}>
                  <Grid container spacing={2} style={{ paddingTop: 5 }}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={2}>
                      <Tooltip title="Kuyrukta bekleyen fiş sayısı" >
                        <Box sx={{ m: 1 }}>
                          <Badge className={classes.Badge} badgeContent={row.ReceiptTotal} color="secondary">
                            <ReceiptLongIcon sx={{ color: '#FF9800' }} />
                          </Badge>
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip title="Kuyruktaki kişi sayısı" >
                        <Box sx={{ m: 1 }}>
                          <Badge className={classes.Badge} badgeContent={row.UserTotal} color="success">
                            <PeopleAltIcon sx={{ color: '#FF9800' }} />
                          </Badge>
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip title="Kuyruktaki online kişi sayısı" >
                        <Box sx={{ m: 1 }}>
                          <Badge className={classes.Badge} badgeContent={row.OnlineUserTotal} color="secondary">
                            <EngineeringIcon sx={{ color: '#FF9800' }} />
                          </Badge>
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={3}></Grid>
                  </Grid>
                  <CardActionArea>
                    <CardContent className={classes.cardsContent}>
                      <Grid container spacing={2}>
                        <Grid item xs={5}></Grid>
                        <Grid item xs={5}>
                          {/* <Chip
                            icon={<BusinessCenterIcon style={{ color: 'orange' }} />}
                          /> */}
                        </Grid>
                      </Grid>
                      <Grid item xs={5}></Grid>
                      <Typography sx={{ padding: '1%', width: '100%', fontWeight: 'bold', textAlign: 'center' }} variant="subtitle1">
                        {row.Description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Grid container spacing={2}>
                      {userRole?.filter(i => i.ROLE_ID == 2).length > 0 || userRole?.filter(i => i.ROLE_ID == 4).length || userRole?.filter(i => i.ROLE_ID == 5).length > 0 ?
                        <Grid item xs={5}><Button size="small" sx={{ color: '#424242' }} fullWidth onClick={() => deleteConfirm(row.MainId)}>Kuyruk sil</Button></Grid> : <Grid item xs={5}></Grid>}
                      <Grid item xs={2}></Grid>
                      <Grid item xs={5}><Button size="small" sx={{ color: '#424242' }} fullWidth onClick={() => { updateJobTailForm(row); setMainId(row.MainId) }}>Güncelle</Button></Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            ))}


          </Grid>
        </CardContent>

        {/* // sx={{ Kullanıcı Ekle Pop-up }} */}

        <Dialog
          open={userAddPopUp}
          onClose={() => setUserAddPopUp(false)}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle sx={{ width: '100%' }} >{"Kullanıcı Ekle"}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <TextField
                  id="standard-basic"
                  label="Ara"
                  value={searchName}
                  onChange={(event) => {
                    setSearchName(event.target.value)
                    setPage(0)
                  }
                  }
                  fullWidth size="small" />
              </Grid>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={6} md={6}>
                <TableContainer sx={{ maxHeight: 440, minHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Sicil No</TableCell>
                        <TableCell align="left">Ad Soyad</TableCell>
                        <TableCell align="left">Önceliklendirme</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userInfo.filter(i => i.NameSurname?.toLowerCase().includes(searchName.toLowerCase()) || i.IdentityNo?.toLowerCase().includes(searchName.toLowerCase()))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => (
                          <TableRow
                            key={row.UserId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center">{row.IdentityNo}</TableCell>
                            <TableCell align="left">{row.NameSurname}</TableCell>
                            <TableCell align="left">
                              <Autocomplete
                                disablePortal
                                options={[{ label: '1', Id: 1 }, { label: '2', Id: 2 }, { label: '3', Id: 3 }, { label: '4', Id: 4 }, { label: '5', Id: 5 }]}
                                size="small"
                                fullWidth
                                disabled={selectedUserPreOrderRight?.find(i => i.UserId == row.UserId) ? true : false}
                                onChange={(event, newValue) => {
                                  setUsersPreOrder([...usersPreOrder.filter(i => i.user != row.UserId), { value: newValue?.Id, user: row.UserId }]);
                                }}
                                renderInput={(params) => <TextField {...params} label="Önceliklendirme" />}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={userInfo.filter(i => i.NameSurname?.toLowerCase().includes(searchName.toLowerCase()) || i.IdentityNo?.toLowerCase().includes(searchName.toLowerCase())).length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TableContainer sx={{ maxHeight: 440, minHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Sicil No</TableCell>
                        <TableCell align="left">Ad Soyad</TableCell>
                        <TableCell align="left">Önceliklendirme</TableCell>
                        <TableCell align="left">Çıkar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedUserPreOrderRight?.map((row) => (
                        <TableRow
                          key={row.UserId}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center">{row.IdentityNo}</TableCell>
                          <TableCell align="left">{row.NameSurname}</TableCell>
                          <TableCell align="left">{row.PreOrder}</TableCell>
                          <TableCell align="left">
                            <Button
                              fullWidth
                              size="Small"
                              variant="outlined"
                              onClick={(event) => { removeUserRight(row.UserId) }}
                            >Çıkar</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack spacing={4} direction="row">
              <Button
                fullWidth
                size="small"
                sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                endIcon={<AddIcon />}
                onClick={() => { selectedUsers(); }}
                disabled={usersPreOrder?.length == 0 ? true : false}
              >Aktar</Button>
            </Stack>
            <Stack spacing={4} direction="row">
              <Button
                fullWidth
                size="small"
                endIcon={<CloseIcon />}
                sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                onClick={() => { setUserAddPopUp(false); setSelectedUserPreOrder([]) }}
              >Kapat</Button>
            </Stack>
          </DialogActions>
        </Dialog>


        {/* // sx={{ Model Kodu Ekle Pop-up }} */}

        <Dialog
          open={modelCodePopUp}
          onClose={() => setModelCodePopUp(false)}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle sx={{ width: '100%' }} >{"Model Kodu Ekle"}</DialogTitle>

          <DialogActions>
            <Grid item xs={6} md={6}>
              <TextField
                id="input-with-icon-textfield"
                label="Ara"
                size="small"
                type="text"
                fullWidth
                value={userModelCodeSearch}
                onChange={(event) => {
                  setUserModelCodeSearch(event.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton color="primary"
                        onClick={() => { ModelCodeGet(); }}
                      >
                        <ManageSearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
              />
            </Grid>
          </DialogActions>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <DataGrid
                  style={{ height: 600, width: '100%', textAlign: 'center', justifyItems: 'center', paddingTop: 20 }}
                  rows={modelCodeInfo}
                  columns={modelCodeColumnsLeft}
                  getRowId={(row) => row.Id}
                  checkboxSelection
                  localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                  onSelectionModelChange={(selection) => {
                    setSelectedModelCodeLeft(selection.map(k => { return modelCodeInfo.find(i => i.Id == k) }))
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <DataGrid
                  style={{ height: 600, width: '100%', textAlign: 'center', justifyItems: 'center', paddingTop: 20 }}
                  rows={selectedModelCodeRight}
                  columns={modelCodeColumnsRight}
                  getRowId={(row) => row.Id}
                  localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack spacing={4} direction="row">
              <Button
                fullWidth
                size="small"
                sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                endIcon={<AddIcon />}
                onClick={() => {
                  setModelCodeInfo([]);
                  var temp = [].concat(selectedModelCodeRight)
                  selectedModelCodeLeft.map(r => {
                    if (temp.find(i => i.UserModelCode == r.UserModelCode)) return

                    temp.push(modelCodeInfo.filter(i => i.Id == r.Id)[0])
                  })
                  setSelectedModelCodeRight(temp)

                }
                }
                disabled={selectedModelCodeLeft?.length == 0 ? true : false}
              >Aktar</Button>
            </Stack>
            <Stack spacing={4} direction="row">
              <Button
                fullWidth
                size="small"
                sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                endIcon={<CloseIcon />}
                onClick={() => { setModelCodePopUp(false); setModelCodeInfo([]); }}
              >Kapat</Button>
            </Stack>
          </DialogActions>
        </Dialog>

        {/* // sx={{ Kuyruk Kullanıcı  Liste Pop-up }} */}

        <Dialog
          open={tailUserPopUp}
          onClose={() => setTailUserPopUp(false)}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle sx={{ width: '100%' }} >{"Kuyruk Kullanıcı Listeleme"}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Stack spacing={4} direction="row">
                  <TextField
                    id="standard-basic"
                    label="Ara"
                    fullWidth size="small"
                    autoComplete="off"
                    value={searchNameList}
                    onChange={(event) => {
                      setSearchNameList(event.target.value)
                      setPage(0)
                    }
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer sx={{ maxHeight: 440, minHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Sicil No</TableCell>
                        <TableCell align="left">Ad Soyad</TableCell>
                        <TableCell align="left">Kuyruk</TableCell>
                        <TableCell align="center">Önceliklendirme</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tailUserList.filter(i => i.IdentityNo?.toLowerCase().includes(searchNameList.toLowerCase()) || i.NameSurname?.toLowerCase().includes(searchNameList.toLowerCase()) || i.Dsc?.toLowerCase().includes(searchNameList.toLowerCase()) || i.PreOrders?.toLowerCase().includes(searchNameList.toLowerCase()))
                        .slice(pageTailList * rowsPerPageTailList, pageTailList * rowsPerPageTailList + rowsPerPageTailList)
                        .map((row) => (
                          <TableRow
                            key={row.UserId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center">{row.IdentityNo}</TableCell>
                            <TableCell align="left">{row.NameSurname}</TableCell>
                            <TableCell align="left">{row.Dsc}</TableCell>
                            <TableCell align="center">{row.PreOrders}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={tailUserList.filter(i => i.IdentityNo?.toLowerCase().includes(searchNameList.toLowerCase()) || i.NameSurname?.toLowerCase().includes(searchNameList.toLowerCase()) || i.Dsc?.toLowerCase().includes(searchNameList.toLowerCase()) || i.PreOrders?.toLowerCase().includes(searchNameList.toLowerCase())).length}
                  rowsPerPage={rowsPerPageTailList}
                  page={pageTailList}
                  onPageChange={handleChangePageUserList}
                  onRowsPerPageChange={handleChangeRowsPerPageTailList}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack spacing={4} direction="row">
              <Button
                fullWidth
                size="small"
                sx={{ color: '#FF9800', backgroundColor: '#424242' }}
                endIcon={<CloseIcon />}
                onClick={() => { setTailUserPopUp(false); }}
              >Kapat</Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Card>
    </Page >
  );
}

export default Assignment;
