import React from 'react';
import { Redirect } from 'react-router-dom';
import Error404 from 'src/views/Error404';
import UnauthorizedView from 'src/views/Unauthorized';
import ErrorLayout from './layouts/Error';
import Layout from './layouts/Page';
import AssignmentView from './views/Assignment';
import AuthorizationView from './views/Authorization';
import ControlApprovalView from './views/ControlApproval';
import DashboardView from './views/Dashboard';
import DealerChangeView from './views/DealerChange';
import DynamicCatalogsView from './views/DynamicCatalogs';
import GeneralFollowView from './views/GeneralFollow';
import ProfileView from './views/Profile';
import StockExcelUploadView from './views/StockExcelUpload';
import TourManagementView from './views/TourManagement';
import DeliveryTimeView from './views/DeliveryTime';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/" />
  },
  {
    path: '/Unauthorized',
    component: ErrorLayout,
    routes: [
      {
        path: '/Unauthorized',
        exact: true,
        component: UnauthorizedView
      },
      {
        component: () => <Redirect to="/Unauthorized" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-404',
        exact: true,
        component: Error404
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: Layout,
    routes: [
      {
        path: '/Kullanici',
        exact: true,
        component: ProfileView
      },
      {
        path: '/YonetimPaneli',
        exact: true,
        component: DashboardView
      },
      {
        path: '/AtamaEkrani',
        exact: true,
        component: AssignmentView
      },
      {
        path: '/KontrolOnayEkrani',
        exact: true,
        component: ControlApprovalView
      },
      {
        path: '/BayiDegisimEkrani',
        exact: true,
        component: DealerChangeView
      },
      {
        path: '/Yetkilendirme',
        exact: true,
        component: AuthorizationView
      },
      {
        path: '/GenelIsTakipListesi',
        exact: true,
        component: GeneralFollowView
      },
      {
        path: '/DinamikEkranlar',
        exact: true,
        component: DynamicCatalogsView
      },
      {
        path: '/StokAdetYukleme',
        exact: true,
        component: StockExcelUploadView
      },
      {
        path: '/TurYonetimi',
        exact: true,
        component: TourManagementView
      },
      {
        path: '/BirimTeslimSuresi',
        exact: true,
        component: DeliveryTimeView
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
